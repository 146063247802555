import markdoc, { type RenderableTreeNode } from '@markdoc/markdoc'
import React from 'react'
import { cn } from '~/utils/cn'

type Props = { content: RenderableTreeNode; className?: string }

export function Markdown({ content, className }: Props) {
	return (
		<div
			className={cn(
				className,
				'prose prose-headings:mt-0 last:prose-ul:mb-0 lg:prose-headings:text-center max-w-none lg:max-w-3xl',
			)}
		>
			{markdoc.renderers.react(content, React)}
		</div>
	)
}
